import React from "react";
import Container from "../ui/Container";

export default function UnsubscribedPage() {
  return (
    <Container>
      <div class="space-y-3">
        <h1 className="text-4xl">You have been unsubscribed</h1>
        <p>Have a nice day!</p>
      </div>
    </Container>
  );
}
